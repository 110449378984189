var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("offer-form", {
    attrs: { "base-offer": _vm.offer, "view-mode": "edit" },
    on: {
      reload: function ($event) {
        return _vm.getOffer()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }