<template>
  <offer-form :base-offer="offer" view-mode="edit" @reload="getOffer()"></offer-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import OfferForm from '@/components/organisms/modules/ecommerce/offer/form/OfferForm';
import { COUNTRY_BY_ID, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { getOfferById } from '@/services/modules/ecommerce/offer';
import metaInfo from '@/mixins/common/metaInfo';
import { list as offerListRoute } from '@/router/private/modules/ecommerce/demand/offer/list';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import apiRequest from '@/utils/apiRequest';

export default {
  name: 'OfferEdit',
  components: {
    OfferForm,
  },
  mixins: [metaInfo, activeClientMixin],
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    offer: null,
    retries: 0,
    MAX_RETRIES: 3,
    TIMEOUT: 2000,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      getCountryById: COUNTRY_BY_ID,
    }),
    isNewOffer() {
      return !this.offerId || this.offerId === 'new';
    },
  },
  async created() {
    this.isLoading = true;
    await this.getOffer();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({ getCountries: GET_COUNTRIES_REQUEST, createToast: CREATE_TOAST }),
    async getOffer() {
      await this.getCountries();

      await apiRequest(async () => {
        const { data } = await getOfferById(this.offerId);
        this.offer = data;
        this.offer.country = this.getCountryById(this.offer.country.iso3);
        this.updateTitle();
      }).catch(async () => {
        if (this.retries <= this.MAX_RETRIES) {
          this.retries++;
          setTimeout(async () => this.getOffer(), this.TIMEOUT * this.retries);
        } else {
          this.createToast(Toast.error('Error loading offer', 'We found a issue loading your offer, try again later'));
          await this.$router.push({ name: offerListRoute.name });
        }
      });
    },
  },
};
</script>
